import './Intro.css';
import { Handshake, CassetteTape, Phone, CalendarFold, House, Menu, X } from 'lucide-react';
import React, { useState } from 'react';

function Intro() {
        // Estado para alternar entre <Menu /> e <X />
    const [isOpen, setIsOpen] = useState(false);

    // Função para alternar o estado
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return(
        <div className='Intro-External'>
        <div id="inicio" className="Intro">
                <div className={`header-mobile ${isOpen ? 'open' : ''}`}>
                    <a onClick={toggleMenu} href='#inicio'><House/> Início</a>
                    <a onClick={toggleMenu} href='/spotify'><CassetteTape /> Portfólio</a>
                    <a onClick={toggleMenu} href='https://works.lumiert.com'><Handshake /> Serviços</a>
                    <a onClick={toggleMenu} href='https://wa.me/54997122776'><CalendarFold /> Booking</a>
                    <a onClick={toggleMenu} href='https://wa.me/54997122776'><Phone /> Contato</a>
                </div>
            <img id="inicio" src="/intro-background.gif" alt="Background animation" className="video-background" />
                <div className={`header ${isOpen ? 'open' : ''}`}>
                <div className="header-top">
                    <img className="logo" src="logo.png"/>
                    <button onClick={toggleMenu} style={{background: 'none', border: 'none', fontSize: '24px', cursor: 'pointer'}} aria-label={isOpen ? 'Fechar Menu' : 'Abrir Menu'}>
                        {isOpen ? <X className='menu' color='white'/> : <Menu className='menu' color='white'/>}
                    </button>
                </div>
                <div className="header-bottom">
                    <a href='#inicio'>Início</a>
                    <a href='/spotify'>Portfólio</a>
                    <a href='https://works.lumiert.com'>Serviços</a>
                    <a href='https://wa.me/54997122776'>Booking</a>
                    <a href='https://wa.me/54997122776'>Contato</a>
                </div>
            </div>
            <div className="intro-text">
                <div className='lu-mi-ert'>
                    <div className='lu-mi-ert-into'>
                        <h1 className="lu-">LU</h1>
                        <h1 className="mi-">MI</h1>
                        <h1 className="ert">ERT</h1>
                    </div>
                    
                    <div className='intro-block'>
                        <img className="intro-profile-pic" src="profile-pic.jpg" />
                        <div className='intro-text-block'>
                            <p>Me chamo Lumiert. Tenho 19 anos, sou DJ e produtor de música eletrônica.</p>
                            <a href="/spotify"className='button button-small'>Ver Portfólio</a>
                        </div>
                    </div>
                </div>                
            </div>
            <span></span>
        </div>
        <div className='sobre'>
                <div className='intro-block-2'>
                    <img className="intro-profile-pic-2" src="profile-pic.jpg" />
                    <div className='intro-text-block-2'>
                        <p>Me chamo Lumiert. Tenho 19 anos, sou DJ e produtor de música eletrônica.</p>
                        <a href="/spotify" className='button button-small'>Ver Portfólio</a>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Intro