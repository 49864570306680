import React, { useEffect, useState } from 'react';

const ExternalRedirect = ({ url }) => {
  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
      window.location.href = url;
  }, [url]);
  
  if (!isRedirecting) {
    return null;
  }

  return (
    <div className='redirect-background'>
    </div>
  );
};

export default ExternalRedirect;
