import './Footer.css';
import {CornerDownRight} from 'lucide-react';

function Footer() {
    return(
        <div className="Footer">
                <div className="footer-left">
                    <div className="footer">
                        <div className="footer-top">
                            <a href="https://lumiert.com"><img src="logo.png" width="200px" /></a>
                        </div>
                        <div className="footer-bottom">
                            <ul>
                                <li>+55 54 9 9712 2776</li>
                                <li>contato@lumiert.com</li>
                                <li>@djlumiert</li>
                            </ul>
                        </div>
                    </div>
                <div className="easy-links">
                    <ul>
                        <li><CornerDownRight size="12" /><a href="#inicio">Início</a></li>
                        <li><CornerDownRight size="12" /><a href="/spotify">Portfólio</a></li>
                        <li><CornerDownRight size="12" /><a href="https://works.lumiert.com">Serviços</a></li>
                        <li><CornerDownRight size="12" /><a href="https://wa.me/54997122776">Booking</a></li>
                        <li><CornerDownRight size="12" /><a href="https://wa.me/54997122776">Contato</a></li>
                    </ul>
                </div>
                </div>
            <p>© 2025 Lumiert. Todos os direitos reservados.</p>
        </div>
    );
}


export default Footer;