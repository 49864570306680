import React from 'react';
import ReactDOM from 'react-dom/client';
import MainPage from './MainPage';
import reportWebVitals from './reportWebVitals';
import ExternalRedirect from './components/ExternalRedirect';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <Routes>
          <Route path='/' element={<MainPage />} />
          {/* Redirecionamento externo */}
          <Route path='/spotify' element={<ExternalRedirect url="https://open.spotify.com/artist/3RUOoztK2hYVZrAbFztJlP?si=rB8qhOJIS9-7JC6zNjJ6yQ" />} />
          <Route path='/soundcloud' element={<ExternalRedirect url="https://soundcloud.com/lumiert" />} />
	        <Route path='/discord' element={<ExternalRedirect url="https://discord.gg/zhUYmeJNyY" />} />        
	        <Route path='/whatsapp' element={<ExternalRedirect url="https://wa.me/54997122776" />} />        
	      </Routes>
      </Router>
  </React.StrictMode>
);

reportWebVitals();

