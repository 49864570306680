import './MainPage.css';

/* Páginas */

import Intro from './Pages/Intro';

import Sobre from './Pages/Sobre';
import Footer from './Pages/Footer';
import Portfolio from './Pages/Portfolio';

function MainPage() {
  return (
    <div className="MainPage">
        <Intro/>
        <Footer/>
    </div>
  );
}

export default MainPage;
